/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-grid.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "../../titles/components/rating-widget/rating-widget.component.ngfactory";
import * as i10 from "../../titles/components/rating-widget/rating-widget.component";
import * as i11 from "../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i12 from "@ngxs/store";
import * as i13 from "../../titles/components/genre-widget/genre-widget.component.ngfactory";
import * as i14 from "../../titles/components/genre-widget/genre-widget.component";
import * as i15 from "../media-image/media-image.component.ngfactory";
import * as i16 from "../media-image/media-image.component";
import * as i17 from "../../../../common/core/config/settings.service";
import * as i18 from "@angular/common";
import * as i19 from "@angular/router";
import * as i20 from "./media-grid.component";
import * as i21 from "../../titles/title-urls.service";
var styles_MediaGridComponent = [i0.styles];
var RenderType_MediaGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MediaGridComponent, data: {} });
export { RenderType_MediaGridComponent as RenderType_MediaGridComponent };
function View_MediaGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "warn"], ["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeAction(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "warn"; _ck(_v, 1, 0, currVal_2); var currVal_5 = _co.actionIcon; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; var currVal_4 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
function View_MediaGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating-widget", [["class", "small"]], null, null, null, i9.View_RatingWidgetComponent_0, i9.RenderType_RatingWidgetComponent)), i1.ɵdid(1, 573440, null, 0, i10.RatingWidgetComponent, [i11.OverlayPanel, i12.Store], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MediaGridComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-widget", [], null, null, null, i13.View_GenreWidgetComponent_0, i13.RenderType_GenreWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i14.GenreWidgetComponent, [], { genres: [0, "genres"], limit: [1, "limit"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.genres; var currVal_1 = 3; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MediaGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "figure", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "media-image", [["size", "medium"]], null, null, null, i15.View_MediaImageComponent_0, i15.RenderType_MediaImageComponent)), i1.ɵdid(2, 49152, null, 0, i16.MediaImageComponent, [i17.Settings], { link: [0, "link"], size: [1, "size"], src: [2, "src"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MediaGridComponent_2)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "figcaption", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_3)), i1.ɵdid(7, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "title"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i19.RouterLinkWithHref, [i19.Router, i19.ActivatedRoute, i18.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_4)), i1.ɵdid(12, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urls.mediaItem(_v.context.$implicit); var currVal_1 = "medium"; var currVal_2 = _v.context.$implicit.poster; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.hasListeners() || !_co.isPerson(_v.context.$implicit)); _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.isPerson(_v.context.$implicit); _ck(_v, 7, 0, currVal_4); var currVal_7 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 9, 0, currVal_7); var currVal_9 = (_v.context.$implicit.type !== "person"); _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_5 = i1.ɵnov(_v, 9).target; var currVal_6 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_8); }); }
export function View_MediaGridComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_1)), i1.ɵdid(1, 278528, null, 0, i18.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MediaGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-grid", [], null, null, null, View_MediaGridComponent_0, RenderType_MediaGridComponent)), i1.ɵdid(1, 49152, null, 0, i20.MediaGridComponent, [i12.Store, i21.TitleUrlsService], null, null)], null, null); }
var MediaGridComponentNgFactory = i1.ɵccf("media-grid", i20.MediaGridComponent, View_MediaGridComponent_Host_0, { actionIcon: "actionIcon", items: "items" }, { actionClick: "actionClick" }, []);
export { MediaGridComponentNgFactory as MediaGridComponentNgFactory };
