import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { TITLE_STATE_MODEL_DEFAULTS } from './title-state-model-defaults';
import { Router } from '@angular/router';
import { CrupdateReview, DeleteReview, LoadRelatedTitles, LoadReviews, LoadTitle, SetTitle } from './title-actions';
import { TitlesService } from '../titles.service';
import { tap } from 'rxjs/operators';
import { ReviewService } from '../../shared/review.service';
import { objectsAreEqual } from '../../../../common/core/utils/objects-are-equal';
var TitleState = /** @class */ (function () {
    function TitleState(router, store, titles, reviews) {
        this.router = router;
        this.store = store;
        this.titles = titles;
        this.reviews = reviews;
    }
    TitleState_1 = TitleState;
    TitleState.backdrop = function (state) {
        return state.title.backdrop;
    };
    TitleState.videoCoverImage = function (state) {
        var image = state.title.images[state.title.images.length - 1];
        return image ? image.url : null;
    };
    TitleState.trailer = function (state) {
        return state.title.videos.find(function (video) { return video.type === 'trailer'; });
    };
    TitleState.episodes = function (state) {
        return state.episodes || state.title.season.episodes;
    };
    TitleState.episode = function (state) {
        return state.episode;
    };
    TitleState.title = function (state) {
        return state.title;
    };
    TitleState.seasonNumbers = function (state) {
        var seasonCount = state.title.season_count;
        if (!seasonCount)
            seasonCount = 0;
        return Array.from(new Array(seasonCount), function (v, i) { return i + 1; });
    };
    TitleState.season = function (state) {
        return state.season;
    };
    TitleState.reviews = function (state) {
        return state.title.reviews;
    };
    TitleState.relatedTitles = function (state) {
        return state.related;
    };
    TitleState.titleOrEpisodeCredits = function (state) {
        if (state.episode) {
            return state.season.credits.concat(state.episode.credits);
        }
        else {
            return state.title.credits;
        }
    };
    TitleState.titleOrEpisodeCast = function (state, credits) {
        return credits.filter(function (person) { return person.pivot.department === 'cast'; });
    };
    TitleState.currentEpisode = function (state) {
        return state.current_episode;
    };
    TitleState.nextEpisode = function (state) {
        return state.next_episode;
    };
    TitleState.prototype.loadTitle = function (ctx, action) {
        var state = ctx.getState();
        // already have this title loaded and no query params changed, can bail
        if (objectsAreEqual(action.params, state.titleQueryParams, false))
            return;
        return this.titles.get(action.titleId, action.params).pipe(tap(function (response) {
            ctx.dispatch(new SetTitle(response, action.params));
        }));
    };
    TitleState.prototype.setTitle = function (ctx, action) {
        var response = action.response;
        var newState = {
            title: action.response.title,
            titleQueryParams: action.params,
            episode: null,
            season: null,
            current_episode: null,
            next_episode: null
        };
        if (action.params.episodeNumber) {
            newState.episode = response.title.season.episodes.find(function (ep) {
                return ep.episode_number === +action.params.episodeNumber;
            });
        }
        if (action.params.seasonNumber) {
            newState.season = response.title.season;
        }
        if (response.current_episode && response.next_episode) {
            newState.current_episode = response.current_episode;
            newState.next_episode = response.next_episode;
        }
        ctx.patchState(newState);
    };
    TitleState.prototype.loadRelatedTitles = function (ctx) {
        return this.titles.getRelatedTitles(ctx.getState().title, { limit: 5 }).pipe(tap(function (response) {
            ctx.patchState({ related: response.titles });
        }));
    };
    TitleState.prototype.loadReviews = function (ctx) {
        // reviews are already loaded
        if (ctx.getState().title.reviews)
            return;
        var params = {
            titleId: ctx.getState().title.id,
            limit: 35,
            withTextOnly: true,
            with: 'user',
        };
        return this.reviews.getAll(params).pipe(tap(function (response) {
            ctx.patchState({
                title: tslib_1.__assign({}, ctx.getState().title, { reviews: response.data })
            });
        }));
    };
    TitleState.prototype.crupdateReview = function (ctx, action) {
        var oldReviews = ctx.getState().title.reviews.slice();
        var index = oldReviews.findIndex(function (r) { return r.id === action.review.id; });
        if (index > -1) {
            oldReviews[index] = action.review;
        }
        else {
            oldReviews.push(action.review);
        }
        ctx.patchState({
            title: tslib_1.__assign({}, ctx.getState().title, { reviews: oldReviews })
        });
    };
    TitleState.prototype.deleteReview = function (ctx, action) {
        return this.reviews.delete([action.review.id]).pipe(tap(function () {
            var newReviews = ctx.getState().title.reviews.filter(function (curr) { return curr.id !== action.review.id; });
            ctx.patchState({
                title: tslib_1.__assign({}, ctx.getState().title, { reviews: newReviews })
            });
        }));
    };
    var TitleState_1;
    tslib_1.__decorate([
        Action(LoadTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, LoadTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "loadTitle", null);
    tslib_1.__decorate([
        Action(SetTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SetTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "setTitle", null);
    tslib_1.__decorate([
        Action(LoadRelatedTitles),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "loadRelatedTitles", null);
    tslib_1.__decorate([
        Action(LoadReviews),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "loadReviews", null);
    tslib_1.__decorate([
        Action(CrupdateReview),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CrupdateReview]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "crupdateReview", null);
    tslib_1.__decorate([
        Action(DeleteReview),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteReview]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState.prototype, "deleteReview", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "backdrop", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "videoCoverImage", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "trailer", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "episodes", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "episode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "title", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "seasonNumbers", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "season", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "reviews", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "relatedTitles", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "titleOrEpisodeCredits", null);
    tslib_1.__decorate([
        Selector([TitleState_1.titleOrEpisodeCredits]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "titleOrEpisodeCast", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "currentEpisode", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TitleState, "nextEpisode", null);
    TitleState = TitleState_1 = tslib_1.__decorate([
        State({
            name: 'title',
            defaults: TITLE_STATE_MODEL_DEFAULTS,
        }),
        tslib_1.__metadata("design:paramtypes", [Router,
            Store,
            TitlesService,
            ReviewService])
    ], TitleState);
    return TitleState;
}());
export { TitleState };
