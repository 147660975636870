import { Store } from '@ngxs/store';
import { LoadPerson } from './state/person-state-actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var PersonResolverService = /** @class */ (function () {
    function PersonResolverService(store) {
        this.store = store;
    }
    PersonResolverService.prototype.resolve = function (route) {
        return this.store.dispatch(new LoadPerson());
    };
    PersonResolverService.ngInjectableDef = i0.defineInjectable({ factory: function PersonResolverService_Factory() { return new PersonResolverService(i0.inject(i1.Store)); }, token: PersonResolverService, providedIn: "root" });
    return PersonResolverService;
}());
export { PersonResolverService };
