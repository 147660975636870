import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LoadPerson} from './state/person-state-actions';

@Injectable({
    providedIn: 'root',
})
export class PersonResolverService implements Resolve<void> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot): Observable<void> {
        return this.store.dispatch(new LoadPerson());
    }
}
