import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {Title} from '../../../models/title';
import {Person} from '../../../models/person';
import {TitleUrlsService} from '../../titles/title-urls.service';
import {Store} from '@ngxs/store';
import {PlayVideo} from '../../player/state/player-state-actions';
import {MEDIA_TYPE} from '../../media-type';

@Component({
    selector: 'media-grid',
    templateUrl: './media-grid.component.html',
    styleUrls: ['./media-grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaGridComponent {
    @Input() actionIcon = 'play-arrow';
    @Input() items: (Title|Person)[] = [];
    @Output() actionClick = new EventEmitter();

    constructor(
        private store: Store,
        public urls: TitleUrlsService
    ) {}

    public playVideo(title: Title) {
        this.store.dispatch(new PlayVideo(title.videos[0], title));
    }

    public isPerson(item: Title|Person) {
        return item.type !== MEDIA_TYPE.PERSON;
    }

    public hasListeners() {
        return this.actionClick.observers.length;
    }

    public executeAction(item: Title|Person) {
        if (this.hasListeners()) {
            this.actionClick.emit(item);
        } else {
            this.playVideo(item as Title);
        }
    }
}
