import { ElementRef, } from '@angular/core';
import { Settings } from '../../../../common/core/config/settings.service';
var MediaImageComponent = /** @class */ (function () {
    function MediaImageComponent(settings) {
        this.settings = settings;
        this.size = 'medium';
    }
    Object.defineProperty(MediaImageComponent.prototype, "src", {
        get: function () {
            return this._src;
        },
        set: function (value) {
            this._src = this.getAbsoluteSrc(value);
        },
        enumerable: true,
        configurable: true
    });
    MediaImageComponent.prototype.getAbsoluteSrc = function (initialSrc) {
        if (!initialSrc) {
            return this.getDefaultPoster();
        }
        else if (initialSrc.indexOf('image.tmdb') > -1) {
            return this.getTmdbSrc(initialSrc);
        }
        else if (initialSrc.indexOf('http') > -1) {
            return initialSrc;
        }
        else {
            return this.getLocalSrc(initialSrc);
        }
    };
    MediaImageComponent.prototype.getTmdbSrc = function (initialSrc) {
        switch (this.size) {
            case 'small':
                return initialSrc.replace('original', 'w92');
            case 'medium':
                return initialSrc.replace('original', 'w300');
            case 'large':
                return initialSrc.replace('original', 'w500');
            case 'original':
                return initialSrc;
        }
    };
    MediaImageComponent.prototype.getLocalSrc = function (initialSrc) {
        var base = this.settings.getBaseUrl(true);
        var suffix;
        switch (this.size) {
            case 'small':
                suffix = initialSrc.replace('original', 'small');
                break;
            case 'medium':
                suffix = initialSrc.replace('original', 'medium');
                break;
            case 'large':
                suffix = initialSrc.replace('original', 'large');
                break;
            case 'original':
                suffix = initialSrc;
        }
        return base + suffix;
    };
    MediaImageComponent.prototype.getDefaultPoster = function () {
        if (this.size === 'large') {
            return this.settings.getAssetUrl('images/default_episode_poster.jpg');
        }
        else {
            return this.settings.getAssetUrl('images/default_title_poster.jpg');
        }
    };
    return MediaImageComponent;
}());
export { MediaImageComponent };
