import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { TitlesService } from '../../../../site/titles/titles.service';
import { AddCredit, AddImage, AddVideo, ChangeCreditOrder, ChangeVideosOrder, CreateEpisode, CreateSeason, CreateTag, CreateTitle, DeleteEpisode, DeleteImage, DeleteSeason, DeleteVideo, DetachTag, HydrateTitle, LoadEpisodeCredits, LoadSelectOptions, RemoveCredit, ResetState, UpdateCredit, UpdateEpisode, UpdateTitle, UpdateVideo } from './crupdate-title-actions';
import { Title } from '../../../../models/title';
import { Toast } from '../../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../../toast-messages';
import { Navigate } from '@ngxs/router-plugin';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { VideoService } from '../../../../site/videos/video.service';
import { ValueLists } from '../../../../../common/core/services/value-lists.service';
import { SeasonService } from '../panels/seasons-panel/season.service';
import { MEDIA_TYPE } from '../../../../site/media-type';
import { ImagesService } from '../../../../site/shared/images.service';
var CrupdateTitleState = /** @class */ (function () {
    function CrupdateTitleState(router, store, titles, toast, videos, images, valueLists, seasons) {
        this.router = router;
        this.store = store;
        this.titles = titles;
        this.toast = toast;
        this.videos = videos;
        this.images = images;
        this.valueLists = valueLists;
        this.seasons = seasons;
    }
    CrupdateTitleState.title = function (state) {
        return state.title;
    };
    CrupdateTitleState.loading = function (state) {
        return state.loading;
    };
    CrupdateTitleState.keywords = function (state) {
        return state.title.keywords;
    };
    CrupdateTitleState.genres = function (state) {
        return state.title.genres;
    };
    CrupdateTitleState.countries = function (state) {
        return state.title.countries;
    };
    CrupdateTitleState.images = function (state) {
        return state.title.images;
    };
    CrupdateTitleState.seasons = function (state) {
        return state.title.seasons;
    };
    CrupdateTitleState.videos = function (state) {
        return state.title.videos;
    };
    CrupdateTitleState.allVideos = function (state) {
        return state.title.all_videos;
    };
    CrupdateTitleState.languageOptions = function (state) {
        return state.selectOptions.languages;
    };
    CrupdateTitleState.prototype.hydrateTitle = function (ctx, action) {
        ctx.patchState({ loading: true });
        var query = { fullCredits: true, keywords: true, countries: true, seasons: true, skipUpdating: true, allVideos: true };
        return this.titles.get(action.id, query).pipe(tap(function (response) {
            ctx.patchState({
                title: response.title,
                loading: false
            });
        }));
    };
    CrupdateTitleState.prototype.loadSelectOptions = function (ctx) {
        this.valueLists.get(['languages']).subscribe(function (response) {
            ctx.patchState({
                selectOptions: {
                    languages: response.languages,
                }
            });
        });
    };
    CrupdateTitleState.prototype.createSeason = function (ctx) {
        ctx.patchState({ loading: true });
        return this.seasons.create(ctx.getState().title.id).pipe(tap(function (response) {
            var title = tslib_1.__assign({}, ctx.getState().title, { seasons: ctx.getState().title.seasons.concat([response.season]) });
            ctx.patchState({ title: title });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.deleteSeason = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.seasons.delete(action.season.id).pipe(tap(function () {
            var newSeasons = ctx.getState().title.seasons.filter(function (s) {
                return s.id !== action.season.id;
            });
            var title = tslib_1.__assign({}, ctx.getState().title, { seasons: newSeasons });
            ctx.patchState({ title: title });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.crupdateTitle = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        return this.titles.update(ctx.getState().title.id, action.payload).pipe(tap(function () {
            _this.toast.open(MESSAGES.TITLE_UPDATE_SUCCESS);
            _this.store.dispatch(new Navigate(['/admin/titles']));
        }, function () {
            _this.toast.open(MESSAGES.TITLE_UPDATE_FAILED);
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.createTitle = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        return this.titles.create(action.payload).pipe(tap(function (response) {
            _this.toast.open(MESSAGES.TITLE_CREATE_SUCCESS);
            _this.store.dispatch(new Navigate(['/admin/titles', response.title.id, 'edit']));
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.updateCredit = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.updateCredit(action.id, action.credit).pipe(tap(function (response) {
            // update matching credit on the state
            var newCredits = ctx.getState().title.credits.map(function (credit) {
                if (credit.pivot.id === response.credit.id) {
                    credit.pivot = response.credit;
                }
                return credit;
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { credits: newCredits }) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.addCredit = function (ctx, action) {
        ctx.patchState({ loading: true });
        var creditablePayload = { type: action.creditable.type, id: action.creditable.id };
        return this.titles.addCredit(action.personId, creditablePayload, action.pivot).pipe(tap(function (response) {
            if (action.creditable.type === MEDIA_TYPE.TITLE) {
                var newCredits = ctx.getState().title.credits.concat([response.credit]);
                ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { credits: newCredits }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.SEASON) {
                var seasons = ctx.getState().title.seasons.map(function (season) {
                    var newSeason = tslib_1.__assign({}, season);
                    if (newSeason.id === action.creditable.id) {
                        newSeason.credits = newSeason.credits.concat([response.credit]);
                    }
                    return newSeason;
                });
                ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.EPISODE) {
                var creditable_1 = action.creditable;
                var seasons = ctx.getState().title.seasons.map(function (season) {
                    if (season.number === creditable_1.season_number) {
                        season.episodes.map(function (episode) {
                            var newEpisode = tslib_1.__assign({}, episode);
                            if (newEpisode.id === action.creditable.id) {
                                newEpisode.credits = newEpisode.credits.concat([response.credit]);
                            }
                            return newEpisode;
                        });
                    }
                    return tslib_1.__assign({}, season);
                });
                ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
            }
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.removeCredit = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.removeCredit(action.credit.pivot.id).pipe(tap(function () {
            if (action.creditable.type === MEDIA_TYPE.TITLE) {
                var newCredits = ctx.getState().title.credits.filter(function (credit) {
                    return credit.pivot.id !== action.credit.pivot.id;
                });
                ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { credits: newCredits }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.SEASON) {
                var seasons = ctx.getState().title.seasons.map(function (season) {
                    if (season.id === action.creditable.id) {
                        season.credits = season.credits.filter(function (c) { return c.id !== action.credit.id; });
                    }
                    return tslib_1.__assign({}, season);
                });
                ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
            }
            else if (action.creditable.type === MEDIA_TYPE.EPISODE) {
                var creditable_2 = action.creditable;
                var seasons = ctx.getState().title.seasons.map(function (season) {
                    if (season.number === creditable_2.season_number) {
                        season.episodes.map(function (episode) {
                            if (episode.id === action.creditable.id) {
                                episode.credits = episode.credits.filter(function (c) { return c.id !== action.credit.id; });
                            }
                            return tslib_1.__assign({}, episode);
                        });
                    }
                    return tslib_1.__assign({}, season);
                });
                ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
            }
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.changeCreditOrder = function (ctx, action) {
        var credits = [];
        if (action.creditable.type === MEDIA_TYPE.TITLE) {
            credits = ctx.getState().title.credits;
            moveItemInArray(credits, action.currentIndex, action.newIndex);
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { credits: credits }) });
        }
        else if (action.creditable.type === MEDIA_TYPE.SEASON) {
            var seasons = ctx.getState().title.seasons.map(function (season) {
                if (season.id === action.creditable.id) {
                    credits = season.credits;
                    moveItemInArray(credits, action.currentIndex, action.newIndex);
                }
                return tslib_1.__assign({}, season);
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
        }
        else if (action.creditable.type === MEDIA_TYPE.EPISODE) {
            var creditable_3 = action.creditable;
            var seasons = ctx.getState().title.seasons.map(function (season) {
                if (season.number === creditable_3.season_number) {
                    season.episodes.map(function (episode) {
                        if (episode.id === action.creditable.id) {
                            credits = episode.credits;
                            moveItemInArray(credits, action.currentIndex, action.newIndex);
                        }
                        return tslib_1.__assign({}, episode);
                    });
                }
                return tslib_1.__assign({}, season);
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
        }
        var order = {};
        credits
            .filter(function (c) { return c.pivot.department === 'cast'; })
            .forEach(function (credit, index) {
            order[index] = credit.pivot.id;
        });
        ctx.patchState({ loading: true });
        return this.titles.changeCreditsOrder(order).pipe(finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.changeVideosOrder = function (ctx, action) {
        var videos = ctx.getState().title.videos.slice();
        moveItemInArray(videos, action.currentIndex, action.newIndex);
        ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { videos: videos }) });
        var order = {};
        videos.forEach(function (video, i) { return order[i] = video.id; });
        ctx.patchState({ loading: true });
        return this.titles.changeVideosOrder(ctx.getState().title.id, order).pipe(finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.loadEpisodeCredits = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.getEpisode(action.episode.id).pipe(tap(function (response) {
            var seasons = ctx.getState().title.seasons.map(function (season) {
                if (season.number === action.episode.season_number) {
                    season.episodes = season.episodes.map(function (episode) {
                        return episode.id === action.episode.id ? response.episode : episode;
                    });
                }
                return season;
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.deleteEpisode = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.deleteEpisode(action.episode.id).pipe(tap(function () {
            var seasons = ctx.getState().title.seasons.map(function (season) {
                var newSeason = tslib_1.__assign({}, season);
                if (newSeason.number === action.episode.season_number) {
                    newSeason.episodes = newSeason.episodes.filter(function (episode) {
                        return episode.id !== action.episode.id;
                    });
                }
                return newSeason;
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.updateEpisode = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.updateEpisode(action.episode.id, action.payload).pipe(tap(function (response) {
            var seasons = ctx.getState().title.seasons.map(function (season) {
                if (season.number === action.episode.season_number) {
                    season.episodes = season.episodes.map(function (episode) {
                        return episode.id === action.episode.id ? response.episode : episode;
                    });
                }
                return tslib_1.__assign({}, season);
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.createEpisode = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.createEpisode(action.season.id, action.payload).pipe(tap(function (response) {
            var seasons = ctx.getState().title.seasons.map(function (season) {
                var newSeason = tslib_1.__assign({}, season);
                if (newSeason.number === response.episode.season_number) {
                    newSeason.episodes = newSeason.episodes.concat([response.episode]);
                }
                return newSeason;
            });
            ctx.patchState({ title: tslib_1.__assign({}, ctx.getState().title, { seasons: seasons }) });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.addVideo = function (ctx, action) {
        var title = tslib_1.__assign({}, ctx.getState().title, { videos: ctx.getState().title.videos.concat([action.video]) });
        ctx.patchState({ title: title });
    };
    CrupdateTitleState.prototype.updateVideo = function (ctx, action) {
        var videos = ctx.getState().title.videos.slice();
        var i = videos.findIndex(function (v) { return v.id === action.video.id; });
        videos[i] = action.video;
        var title = tslib_1.__assign({}, ctx.getState().title, { videos: videos });
        ctx.patchState({ title: title });
    };
    CrupdateTitleState.prototype.deleteVideo = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.videos.delete([action.video.id]).pipe(tap(function () {
            var videos = ctx.getState().title.videos.slice();
            var i = videos.findIndex(function (v) { return v.id === action.video.id; });
            videos.splice(i, 1);
            var title = tslib_1.__assign({}, ctx.getState().title, { videos: videos });
            ctx.patchState({ title: title });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.addImage = function (ctx, action) {
        var title = tslib_1.__assign({}, ctx.getState().title, { images: ctx.getState().title.images.concat([action.image]) });
        ctx.patchState({ title: title });
    };
    CrupdateTitleState.prototype.deleteImage = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.images.delete(action.image.id).pipe(tap(function () {
            var images = ctx.getState().title.images.slice();
            var i = images.findIndex(function (v) { return v.id === action.image.id; });
            images.splice(i, 1);
            var title = tslib_1.__assign({}, ctx.getState().title, { images: images });
            ctx.patchState({ title: title });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.createTag = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.createTag(ctx.getState().title.id, action.tag).pipe(tap(function (response) {
            var _a;
            var type = action.tag.type === 'keyword' ? 'keywords' : 'genres';
            var oldTags = ctx.getState().title[type];
            var exists = oldTags.find(function (tag) { return tag.id === response.tag.id; });
            if (exists)
                return;
            ctx.patchState({
                title: tslib_1.__assign({}, ctx.getState().title, (_a = {}, _a[type] = oldTags.concat([response.tag]), _a))
            });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.detachTag = function (ctx, action) {
        ctx.patchState({ loading: true });
        return this.titles.detachTag(ctx.getState().title.id, action.tag).pipe(tap(function () {
            var _a;
            var type = action.tag.type === 'keyword' ? 'keywords' : 'genres';
            var oldTags = ctx.getState().title[type];
            var i = oldTags.findIndex(function (tag) { return tag.id === action.tag.id; });
            if (i > -1)
                oldTags.splice(i, 1);
            ctx.patchState({
                title: tslib_1.__assign({}, ctx.getState().title, (_a = {}, _a[type] = oldTags.slice(), _a))
            });
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    CrupdateTitleState.prototype.resetState = function (ctx) {
        ctx.patchState({
            title: new Title(),
            loading: false,
        });
    };
    tslib_1.__decorate([
        Action(HydrateTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, HydrateTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "hydrateTitle", null);
    tslib_1.__decorate([
        Action(LoadSelectOptions),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "loadSelectOptions", null);
    tslib_1.__decorate([
        Action(CreateSeason),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "createSeason", null);
    tslib_1.__decorate([
        Action(DeleteSeason),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteSeason]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "deleteSeason", null);
    tslib_1.__decorate([
        Action(UpdateTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "crupdateTitle", null);
    tslib_1.__decorate([
        Action(CreateTitle),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CreateTitle]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "createTitle", null);
    tslib_1.__decorate([
        Action(UpdateCredit),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateCredit]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "updateCredit", null);
    tslib_1.__decorate([
        Action(AddCredit),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddCredit]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "addCredit", null);
    tslib_1.__decorate([
        Action(RemoveCredit),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, RemoveCredit]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "removeCredit", null);
    tslib_1.__decorate([
        Action(ChangeCreditOrder),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ChangeCreditOrder]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "changeCreditOrder", null);
    tslib_1.__decorate([
        Action(ChangeVideosOrder),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ChangeVideosOrder]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "changeVideosOrder", null);
    tslib_1.__decorate([
        Action(LoadEpisodeCredits),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, LoadEpisodeCredits]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "loadEpisodeCredits", null);
    tslib_1.__decorate([
        Action(DeleteEpisode),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteEpisode]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "deleteEpisode", null);
    tslib_1.__decorate([
        Action(UpdateEpisode),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateEpisode]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "updateEpisode", null);
    tslib_1.__decorate([
        Action(CreateEpisode),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CreateEpisode]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "createEpisode", null);
    tslib_1.__decorate([
        Action(AddVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "addVideo", null);
    tslib_1.__decorate([
        Action(UpdateVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "updateVideo", null);
    tslib_1.__decorate([
        Action(DeleteVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "deleteVideo", null);
    tslib_1.__decorate([
        Action(AddImage),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddImage]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "addImage", null);
    tslib_1.__decorate([
        Action(DeleteImage),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteImage]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "deleteImage", null);
    tslib_1.__decorate([
        Action(CreateTag),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, CreateTag]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "createTag", null);
    tslib_1.__decorate([
        Action(DetachTag),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DetachTag]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "detachTag", null);
    tslib_1.__decorate([
        Action(ResetState),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState.prototype, "resetState", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "title", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "loading", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "keywords", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "genres", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "countries", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "images", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "seasons", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "videos", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "allVideos", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], CrupdateTitleState, "languageOptions", null);
    CrupdateTitleState = tslib_1.__decorate([
        State({
            name: 'crupdateTitle',
            defaults: {
                title: new Title(),
                loading: false,
                selectOptions: {
                    languages: [],
                }
            },
        }),
        tslib_1.__metadata("design:paramtypes", [Router,
            Store,
            TitlesService,
            Toast,
            VideoService,
            ImagesService,
            ValueLists,
            SeasonService])
    ], CrupdateTitleState);
    return CrupdateTitleState;
}());
export { CrupdateTitleState };
