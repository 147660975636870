/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-item-header.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/ui/material-navbar/material-navbar.component.ngfactory";
import * as i3 from "../../../../../common/core/ui/material-navbar/material-navbar.component";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "../../../../../common/auth/current-user";
import * as i6 from "../../../../../common/core/ui/breakpoints.service";
import * as i7 from "../../../search/seach-input/search-input.component.ngfactory";
import * as i8 from "../../../search/seach-input/search-input.component";
import * as i9 from "../../../search/search.service";
import * as i10 from "@angular/router";
import * as i11 from "../../titles.service";
import * as i12 from "../../../people/people.service";
import * as i13 from "@ngxs/store";
import * as i14 from "../../title-urls.service";
import * as i15 from "./media-item-header.component";
var styles_MediaItemHeaderComponent = [i0.styles];
var RenderType_MediaItemHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MediaItemHeaderComponent, data: {} });
export { RenderType_MediaItemHeaderComponent as RenderType_MediaItemHeaderComponent };
export function View_MediaItemHeaderComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "material-navbar", [["menuPosition", "primary"]], [[2, "transparent", null]], null, null, i2.View_MaterialNavbar_0, i2.RenderType_MaterialNavbar)), i1.ɵdid(1, 114688, null, 0, i3.MaterialNavbar, [i4.Settings, i5.CurrentUser, i1.ElementRef, i6.BreakpointsService], { menuPosition: [0, "menuPosition"], transparent: [1, "transparent"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "search-input", [["class", "nav-searchbar"]], null, null, null, i7.View_SearchInputComponent_0, i7.RenderType_SearchInputComponent)), i1.ɵdid(3, 114688, null, 0, i8.SearchInputComponent, [i9.SearchService, i10.Router, i11.TitlesService, i12.PeopleService, i13.Store, i14.TitleUrlsService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "header-overlay"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "primary"; var currVal_2 = (_co.transparent || !!_co.backdrop); _ck(_v, 1, 0, currVal_1, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).transparent; _ck(_v, 0, 0, currVal_0); }); }
export function View_MediaItemHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-item-header", [], [[4, "background-image", null], [2, "no-backdrop", null]], null, null, View_MediaItemHeaderComponent_0, RenderType_MediaItemHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i15.MediaItemHeaderComponent, [i6.BreakpointsService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).backgroundImage; var currVal_1 = i1.ɵnov(_v, 1).noBackdrop; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var MediaItemHeaderComponentNgFactory = i1.ɵccf("media-item-header", i15.MediaItemHeaderComponent, View_MediaItemHeaderComponent_Host_0, { backdrop: "backdrop", transparent: "transparent" }, {}, []);
export { MediaItemHeaderComponentNgFactory as MediaItemHeaderComponentNgFactory };
