<media-item-header [backdrop]="backdropImage$ | async"></media-item-header>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<div class="page-title container" *ngIf="title$ | async as title">
    <div class="text">
        <a [routerLink]="urls.mediaItem(title)">{{ title.name }}:</a>&nbsp;
        <span trans>Episode List</span>
    </div>
    <div class="inputs">
        <div class="input-container">
            <label for="season">
                <span trans>Season</span>:
            </label>
            <select name="season" id="season" [formControl]="seasonModel">
                <option [ngValue]="number" *ngFor="let number of seasonNumbers$ | async">{{number}}</option>
            </select>
        </div>
    </div>
</div>

<div class="episode-list">
    <div class="episode" *ngFor="let episode of episodes$ | async">
        <div class="container">
            <figure>
                <media-image [src]="episode.poster" [routerLink]="['episode', episode.episode_number]" size="medium"></media-image>
                <figcaption>
                    <season-episode-number [episode]="episode"></season-episode-number>
                </figcaption>
            </figure>
            <div class="media-body">
                <div class="header">
                    <a [routerLink]="['episode', episode.episode_number]" class="title">{{episode.name}}</a>
                    <div class="release_date">{{episode.release_date | formattedDate}}</div>
                </div>
                <div class="rating">
                    <rating-widget [item]="episode"></rating-widget>
                </div>
                <div class="description">
                    <ng-container *ngIf="episode.description; else noDescription">
                        <div moreLessText="500">{{episode.description}}</div>
                    </ng-container>
                    <ng-template #noDescription>{{'We do not have an overview for this episode yet.' | trans}}</ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<footer></footer>