import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SearchEverything } from '../state/search-state-actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
var SearchResultsResolverService = /** @class */ (function () {
    function SearchResultsResolverService(store, router) {
        this.store = store;
        this.router = router;
    }
    SearchResultsResolverService.prototype.resolve = function (route) {
        if (route.queryParams.query) {
            return this.store.dispatch(new SearchEverything(route.queryParams.query));
        }
        else {
            return this.router.navigate(['/']);
        }
    };
    SearchResultsResolverService.ngInjectableDef = i0.defineInjectable({ factory: function SearchResultsResolverService_Factory() { return new SearchResultsResolverService(i0.inject(i1.Store), i0.inject(i2.Router)); }, token: SearchResultsResolverService, providedIn: "root" });
    return SearchResultsResolverService;
}());
export { SearchResultsResolverService };
