import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleUrlsService } from '../../title-urls.service';
import { ToggleGlobalLoader } from '../../../../state/app-state-actions';
import { filter } from 'rxjs/operators';
var SeasonPageComponent = /** @class */ (function () {
    function SeasonPageComponent(route, router, store, urls) {
        this.route = route;
        this.router = router;
        this.store = store;
        this.urls = urls;
        this.seasonModel = new FormControl();
    }
    SeasonPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function () {
            // TODO: remove settimout
            setTimeout(function () { return _this.store.dispatch(new ToggleGlobalLoader(false)); });
        });
        this.store.select(TitleState.season)
            .pipe(filter(function (season) { return !!season; }))
            .subscribe(function (season) {
            _this.seasonModel.setValue(season.number, {
                emitEvent: false
            });
        });
        this.seasonModel.valueChanges.subscribe(function (seasonNumber) {
            var title = _this.store.selectSnapshot(TitleState.title);
            _this.router.navigate(_this.urls.season(title, seasonNumber));
        });
    };
    tslib_1.__decorate([
        Select(TitleState.episodes),
        tslib_1.__metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "episodes$", void 0);
    tslib_1.__decorate([
        Select(TitleState.title),
        tslib_1.__metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "title$", void 0);
    tslib_1.__decorate([
        Select(TitleState.seasonNumbers),
        tslib_1.__metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "seasonNumbers$", void 0);
    tslib_1.__decorate([
        Select(TitleState.backdrop),
        tslib_1.__metadata("design:type", Observable)
    ], SeasonPageComponent.prototype, "backdropImage$", void 0);
    return SeasonPageComponent;
}());
export { SeasonPageComponent };
